import { useMemo } from "react";
import {
  createHTTP,
  HTTPEndpointInput,
  HTTPError,
  HTTPRequestOptions,
} from "@superdispatch/http";
import { ShippingDetailsDTO } from "./ShippingDetailsDTO";
import {
  CarrierPayDTO,
  carrierPaySchema,
  RecentPostingDTO,
  recentPostingSchema,
  RecentMovedLoadDTO,
  recentMovedLoadSchema,
} from "./CarrierPayDTO";
import { URITemplateParams } from "@superdispatch/uri";
import { array, Schema } from "yup";

interface APISuccessResponse<Data> {
  meta: {
    status: "success";
  };
  data: Data;
}

interface APIErrorResponse {
  meta: { status: "error" };
  message: string;
}

interface APIFailureResponse {
  meta: {
    status: "fail";
  };
  data: {
    message: string;
    details: [
      {
        loc: string[];
        msg: string;
        type: string;
        ctx?: {
          enum_values?: string[];
        };
      }
    ];
  };
}

type APIError = Error & APIFailureResponse["data"];

export function isAPIError(error: unknown): error is APIError {
  return error instanceof Error && "details" in error;
}

const http = createHTTP({
  baseURL:
    import.meta.env.VITE_APP_TARGET === "production"
      ? "https://pricing-insights.superdispatch.com/api"
      : "https://staging.pricing-insights.superdispatch.org/api",
});

export function requestJSON<
  TData,
  TParams extends URITemplateParams = URITemplateParams
>(
  input: HTTPEndpointInput<TParams>,
  options?: HTTPRequestOptions & { schema?: Schema<any> }
): Promise<TData> {
  return http
    .requestJSON<APISuccessResponse<TData>>(input, options)
    .then(({ data }) => {
      if (options?.schema) {
        return options.schema.cast(data);
      }
      return data;
    })
    .catch((error) => {
      if (error instanceof HTTPError) {
        return error.response
          .json()
          .then((response: APIFailureResponse | APIErrorResponse) => {
            if ("data" in response) {
              const error = new Error(response.data.message);
              throw Object.assign(error, response.data);
            }

            throw new Error(response.message);
          });
      }

      throw new Error(error.message || "Failed to fetch");
    });
}

export function usePricingInsightsAPI() {
  return useMemo(() => {
    function getRecentPostings(payload: ShippingDetailsDTO) {
      return requestJSON<RecentPostingDTO[]>(
        "POST /internal/v1/get-recent-postings",
        {
          json: payload,
          schema: array(recentPostingSchema),
        }
      );
    }

    function getRecentMoves(payload: ShippingDetailsDTO) {
      return requestJSON<RecentMovedLoadDTO[]>(
        "POST /internal/v1/get-recent-moves",
        {
          json: payload,
          schema: array(recentMovedLoadSchema),
        }
      );
    }

    function calculateCarrierPay(payload: ShippingDetailsDTO) {
      return requestJSON<CarrierPayDTO | null>(
        "POST /internal/v1/predict-carrier-pay",
        {
          json: payload,
          schema: carrierPaySchema.nullable(),
        }
      );
    }

    return {
      getRecentPostings,
      getRecentMoves,
      calculateCarrierPay,
    };
  }, []);
}
