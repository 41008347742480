import { logError } from "shared/services/MonitoringService";
import { onAnalyticsReady } from "./AnalyticsService";

declare global {
  interface Window {
    Intercom?: (...args: any[]) => void;
    intercomSettings?: {
      hide_default_launcher?: boolean;
    };
  }
}

window.intercomSettings = {
  ...window.intercomSettings,
  hide_default_launcher: true,
};

function invokeIntercom(command: string, ...args: any[]): void {
  onAnalyticsReady(() => {
    try {
      if (command === "update" && typeof args[0] === "object") {
        window.intercomSettings = {
          ...window.intercomSettings,
          ...(args[0] as typeof window.intercomSettings),
        };
      }

      window.Intercom?.(command, ...args);
    } catch (error: unknown) {
      logError(error, "Intercom");
    }
  });
}

export function showNewIntercomMessage(): void {
  invokeIntercom("showMessages");
}

export function showIntercomLauncher(): void {
  invokeIntercom("show");
}

export function onUnreadCountChange(fn: (unreadCount: number) => void) {
  invokeIntercom("onUnreadCountChange", fn);
}

export function onShow(fn: () => void) {
  invokeIntercom("onShow", fn);
}

export function hideIntercomLauncher() {
  invokeIntercom("update", {
    hide_default_launcher: true,
  });
}
