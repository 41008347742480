import { useEffect } from "react";
import { logError } from "shared/services/MonitoringService";
import { IParseOptions, parse, stringify } from "qs";
import { getCook } from "shared/helpers/DomHelpers";
import { tryParse } from "shared/utils/JSONUtils";
import { LocalStore } from "shared/helpers/Store";
import { generateRandomString } from "shared/helpers/StringHelpers";

export type URLSearchQuery = Record<string, string | undefined>;
export type URLSearchQueryInit = Record<string, any>;

export function parseSearchQuery(
  search: string,
  options?: Omit<IParseOptions, "ignoreQueryPrefix">
): URLSearchQuery {
  return parse(search, {
    ...options,
    ignoreQueryPrefix: true,
  }) as URLSearchQuery;
}

export function stringifySearchQuery(query: URLSearchQueryInit): string {
  return stringify(query, {
    skipNulls: true,
    sort: (a: string, b: string) =>
      a.toLowerCase().localeCompare(b.toLowerCase()),
  });
}

{
  const segmentKey =
    import.meta.env.VITE_APP_TARGET === "production"
      ? "opvudJq0nvYbSQhuSLHlkrESj0tPqFDT"
      : import.meta.env.VITE_APP_TARGET === "staging"
      ? "gsvl4hAVjmZmXzKrMfAFWt0tenYyWS8k"
      : null;

  if (segmentKey) {
    tryRun(() => {
      analytics.load(segmentKey);
      analytics.page();
    });
  }
}

function tryRun(fn: () => void): void {
  if (typeof analytics != "undefined") {
    try {
      fn();
      return;
    } catch (error) {
      logError(error, "Analytics");
    }
  }
}

export function onAnalyticsReady(fn: () => void): void {
  tryRun(() => {
    analytics.ready(() => {
      fn();
    });
  });
}

export function resetAnalytics(): void {
  tryRun(() => {
    analytics.reset();
  });
}

export function identifyUser() {
  const prevUserId = getOtherSegmentAppUserId();
  return new Promise((resolve) => {
    onAnalyticsReady(() => {
      analytics.identify({
        prev_analytics_id: prevUserId,
        custom_anonymous_id: getCustomAnonymousId(),
      });
      analytics.page();
      resolve(true);
    });
  });
}

// Gets user id from other Segment app.
// Segment sets user id to both Cookie and Local Storage. Cookie can be cross domain but local storage is only per domain.
// So whenever cookie value is different with local storage value, it means some other Segment application added this user_id.
function getOtherSegmentAppUserId() {
  const userIdFromCookie = getCook("ajs_user_id");
  const userIdFromStorage = tryParse(localStorage.getItem("ajs_user_id"));

  return userIdFromCookie !== userIdFromStorage ? userIdFromCookie : undefined;
}

export function getUserId() {
  return analytics.user().id();
}

export function getAnonymousId() {
  return analytics.user().anonymousId();
}

function getUTMs() {
  const query = parseSearchQuery(window.location.search);
  const tags: Record<string, unknown> = {};

  Object.keys(query).forEach((key) => {
    if (key.startsWith("utm_")) {
      tags[key] = query[key];
    }
  });

  return tags;
}

export function trackEvent(
  eventName: string,
  data?: Record<string, string | number | undefined | boolean | null>,
  callback?: () => void
) {
  const queryUtms = getUTMs();

  const properties = {
    ...queryUtms,
    ...data,
    source: "Pricing Insights Web",
    utm_source: data?.utm_source || queryUtms.utm_source || "Pricing Insights",
  };

  tryRun(() => {
    analytics.track(eventName, properties, callback);
  });

  if (import.meta.env.MODE !== "production") {
    // eslint-disable-next-line no-console
    console.info('Analytics: "%s": %O', eventName, properties);
    callback?.();
  }
}

export function useTrackEvent(name: string) {
  useEffect(() => {
    trackEvent(name);
  }, [name]);
}

function getCustomAnonymousId() {
  const CUSTOM_ANONYMOUS_ID_STORAGE_KEY = "custom_anonymous_id";
  const customAnonymousId = LocalStore.get(CUSTOM_ANONYMOUS_ID_STORAGE_KEY);

  if (!customAnonymousId) {
    const newCustomAnonymousId = generateRandomString();
    LocalStore.set(CUSTOM_ANONYMOUS_ID_STORAGE_KEY, newCustomAnonymousId);
    return newCustomAnonymousId;
  }

  return customAnonymousId;
}
